import React from 'react';
import axios from 'axios'
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { withRouter } from "react-router-dom"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class NameForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            show2: false
        };
    }

    render() {
        const handleClose = () => this.setState({ show: false })
        const handleOpen = () => this.setState({ show: true })
        const handleStateClose = () => window.location = 'http://google.com'
        const handleStateOpen = () => this.setState({ show2: true })
        const handleSucess = () => this.props.history.push("/thank-you");
        return (
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    estate:'',
                    email: '',
                    acceptTerms: 'true'
                }}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string()
                        .required('First Name is required'),
                    lastName: Yup.string()
                        .required('Last Name is required'),
                    estate: Yup.string()
                        .required('State is required'),
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),
                    acceptTerms: Yup.bool()
                        .oneOf([true], 'Accept Ts & Cs is required')
                })}
                onSubmit={values => {
                    if(values.estate === 'VA' || values.estate === 'GA' || values.estate === 'NC' || values.estate === 'SC' || values.estate === 'TN'){
                        axios.post('https://django.platinumseltzer.com/api/entry/', {
                            firstname: values.firstName,
                            lastname: values.lastName,
                            email: values.email,
                            state: values.estate,
                            sweeps: 23,
                        }).then(function (response) {
                            cookies.set('myEmail', values.email, {path: '/'});
                            handleSucess()
                        }).catch(function (response) {
                            handleOpen()
                        })
                    }else {
                        axios.post('https://django.platinumseltzer.com/api/entry/', {
                            firstname: values.firstName,
                            lastname: values.lastName,
                            email: values.email,
                            state: values.estate,
                            sweeps: 23,
                        }).then(function (response) {
                            handleStateOpen()
                        }).catch(function (response) {
                            handleOpen()
                        })
                    }

                }}>
                {({errors, status, touched}) => (
                    <Form>
                        <Modal show={this.state.show} onHide={handleClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>An error has occured please try again later.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>We Apologize, The system was unable to save your entry please try again shortly.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.show2} onHide={handleStateClose} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Invalid Entry State.</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>We Apologize, You must be a resident of either GA, NC, SC, TN and VA to enter.</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleStateClose}>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>
                         <div className="container-fluid Name-Form-Container">
                        <div className="form-group">
                            <Field name="firstName" type="text" placeholder="First Name"
                                   className={'Name-Text form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback text-red"/>
                        </div>
                        <div className="form-group">

                            <Field name="lastName" type="text" placeholder="Last Name"
                                   className={'Name-Text form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="lastName" component="div" className="invalid-feedback text-red"/>
                        </div>
                        <div className="form-group">

                            <Field name="email" type="text" placeholder="Email"
                                   className={'Name-Text form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                            <ErrorMessage name="email" component="div" className="invalid-feedback text-red"/>
                        </div>
                        <div className="form-group">
                            <Field name="estate" as="select" className={' Name-Select form-control' + (errors.estate && touched.estate ? ' is-invalid' : '')}>
                                <option value="">Select Your State</option>
                                <option value="VA">Virginia</option>
                                <option value="GA">Georgia</option>
                                <option value="NC">North Carolina</option>
                                <option value="SC">South Carolina</option>
                                <option value="TN">Tennessee</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NC">North Carolina</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VA">Virginia</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </Field>
                            <ErrorMessage name="estate" component="div" className="invalid-feedback text-red"/>
                        </div>
                        <div className="form-group form-check">
                            <Field type="checkbox" name="acceptTerms"
                                   className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')}/>
                            <label htmlFor="acceptTerms" className="form-check-label">I have read and agree to the Official Rules*</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback"/>
                        </div>
                        <div className="form-group">
                            <button type="submit"  className="btn btn-primary Name-Button mr-2">Register</button>
                        </div>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default withRouter(NameForm);
