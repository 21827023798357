import React from 'react';
import Image from 'react-bootstrap/Image';
import DateForm from "./DateForm";
import Footer from "./Footer";
import './App.css';
import product from './img/product.png'
import logo from './img/logo.png'
import tagline from './img/tagline.png'
import tagline2 from './img/tagline2.png'
import tagline3 from './img/tagline3.png'

const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`


/*function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Ultra Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )

}
*/

class AgeGate extends React.Component {
    render() {
        return (
            <div className="App">
                <div className="App2">
                    <div className="container-fluid Main-Container">
                        <div className="row h-100 w-100 justify-content-end">
                            <div className="Main-Right d-none d-md-flex col-3">
                                <Image src={product} alt="Michelob Pure Gold" className=" img-fluid Product-Image no-gutters"/>
                            </div>
                            <div className="Main-Center d-flex col-12 col-md-6">
                                <div className="container-fluid">
                                    <div className="row Top-Mobile align-items-end justify-content-center">
                                        <div className="d-flex col-12 justify-content-center">
                                            <Image src={logo} className="img-fluid Logo-Image" alt="Michelob Pure Gold Sweeps"/>
                                        </div>
                                    </div>
                                    <div className="container-fluid transmiddle">
                                        <div className="contentright">
                                            <div className="universec33 ultrablue pt-2 pb-0">Enter Now for A Chance to</div>
                                            <div className="universec59 ultrablue pt-2">WIN $500!</div>
                                            <div className="sharp36 ultrablue pt-3">GET MICHELOB ULTRA</div>
                                            <div className="sharp71 ultrayellow pt-1">PURE GOLD</div>

                                            <div className="universec33 ultrablue pt-3">Today at Your Favorite Retailer
                                            </div>
                                            <div className="universeb28 ultrablue pt-5">
                                                YOU MUST BE OF LEGAL DRINKING AGE
                                            </div>
                                            <div className="universec28 ultrablue pt-3">
                                                PLEASE FILL OUT YOUR DATE OF BIRTH To Get Started!
                                            </div>
                                            <div><DateForm/></div>
                                        </div>
                                        <div className="d-flex d-md-none col-12 justify-content-center">
                                            <Image src={tagline3} alt="Michelob Pure Gold" className="img-fluid Tag-Image"/>
                                        </div>
                                        <div className="d-flex col-12 justify-content-center">
                                        <Footer/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-Left d-none d-md-flex col-3">
                                <Image src={tagline2} alt="Michelob Pure Gold" className="img-fluid Tag-Image2 no-gutters"/>
                                <Image src={tagline} alt="Michelob Pure Gold" className="img-fluid Tag-Image no-gutters"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AgeGate;
